@import "style";
@import "mobile-menu";
@import "animations";
@import "services";

.main-section {
  height: 70vh;
  padding: 0 10vw;
  position: relative;

  .main-section__carrousel {
    .item {
      height: 70vh;
      overflow: hidden;

      img,
      object {
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .main-section__banner {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    left: 0;
    padding-top: 10vh;
    height: 100%;
    display: flex;
    justify-content: center;

    h1 {
      width: 60%;
      color: #ffffff;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8), 0px 0px 2px rgba(0, 0, 0, 1);
    }
  }

  .main-section__bg {
    background-color: rgb(237, 205, 31);
    width: calc(80vw - 5rem);
    height: 100%;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.description-section {
  height: 50vh;
  display: flex;
  margin-top: 0.5rem;

  .description-section__content {
    flex-basis: 50%;
    background-color: rgb(247, 247, 247);
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      width: 50%;
      color: rgb(30, 45, 59);
    }
  }

  .description-section__img {
    flex-basis: 50%;
    overflow: hidden;
    position: relative;

    background-image: url("../assets/webp/home_description.webp"), url("../assets/jpg/home_description.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

.home-separator {
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 2rem;
    width: 50%;
  }
}

.contact {
  .contact__map {
    display: flex;
    margin: 3rem 0;
    overflow: hidden;
    justify-content: center;

    iframe {
      width: 60%;
      height: 40vh;
      margin-top: -55px;
    }
  }

  .contact__description {
    display: flex;
    justify-content: center;

    .contact__description--container {
      width: 60%;
      display: flex;
      justify-content: space-between;

      section {
        // flex-basis: 33%;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        h3 {
          font-size: 1.5rem;
          margin-bottom: 1rem;
          color: rgb(30, 45, 59);
        }

        p {
          color: rgb(59, 58, 58);
          font-size: 1.1rem;
        }
      }
    }
  }

  .contact__form {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    .contact__form--container {
      min-width: 400px;
      width: 30vw;

      h2 {
        text-align: center;
      }

      #response_email:not(:empty) {
        padding: 1rem;
        margin-top: 1rem;
        border: 2px solid $primary_color;
        border-radius: 4px;
      }

      form {
        display: flex;
        flex-direction: column;

        input,
        textarea {
          background-color: rgb(247, 247, 247);
          border: 1px solid rgb(247, 247, 247);
          padding: 3px 12px;
          color: rgb(61, 89, 117);
          line-height: 1.5rem;

          &.green {
            border: 1px solid green;
          }
        }

        label {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }

        .submit {
          text-align: right;
          button {
            margin-top: 1rem;
            width: 10rem;
            color: $primary_color;
            background: #323232;
            padding: 0.8rem 1rem;
            border: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .main-section {
    height: 80vh;
    padding: 0;

    .main-section__carrousel {
      height: 80vh;

      .item {
        height: 80vh;

        img,
        object {
          object-fit: cover;
        }
      }
    }

    .main-section__banner {
      padding-top: 0;
      align-items: center;

      h1 {
        font-size: 2rem;
        text-align: center;
        width: 90%;
      }
    }

    .main-section__bg {
      width: calc(100vw - 5rem);
    }
  }

  .description-section {
    flex-direction: column;
    height: auto;

    .description-section__content {
      flex-basis: auto;
      padding: 3rem;

      h2 {
        width: 100%;
      }
    }

    .description-section__img {
      display: none;
    }
  }

  .home-separator {
    padding: 3rem;
    height: auto;

    h2 {
      width: 100%;
    }
  }

  .divider {
    display: none;
  }

  .contact {
    margin: 3rem 0;

    .contact__map {
      margin: 1rem 0;

      iframe {
        width: 90%;
        height: 60vh;
      }
    }

    .contact__description {
      .contact__description--container {
        flex-direction: column;
        width: 100%;
        padding: 0 2rem;

        section {
          margin-right: 0 !important;

          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .contact__form {
      padding: 0 3rem;

      .contact__form--container {
        min-width: auto;
        width: 100%;
      }
    }
  }
}
