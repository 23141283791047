@import "style";
@import "mobile-menu";
@import "animations";

.services {
  margin-top: 3rem;
  padding: 0 15vw;

  .services__header {
    text-align: center;
  }

  .services__items {
    display: flex;
    margin-top: 2rem;

    .services__item {
      // min-width: 350px;
      flex-basis: 33%;

      img,
      object {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      .services__item--title {
        height: 8rem;
        background-color: rgb(50, 50, 50);
        text-align: center;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        margin-top: -5px;
        z-index: 1;
        color: $white;

        h3 {
          font-size: 1.5rem;
        }
      }
    }
  }

  .services__more {
    margin-top: 3rem;
    text-align: center;
  }
}

.other-services {
  margin-top: 5rem;
  padding: 0 15vw;

  .other-services__items {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .other-services__item {
      display: flex;
      margin-bottom: 2rem;
      padding-bottom: 0.5rem;
      box-shadow: 0px 3px 5px 0px #00000045;

      > div {
        flex: 1;
      }

      .other-services__item--title {
        display: flex;
        align-items: center;
        padding: 0 2rem;

        svg {
          width: 3rem;
          height: 3rem;
          flex-basis: 5rem;
          margin-bottom: 0.5rem;
        }
      }

      .other-services__item--description {
        text-align: justify;
        padding: 1rem;
      }
    }
  }
}

.service {
  padding: 0 15vw;
  margin-top: 3rem;
  p {
    text-align: justify;
  }

  .service__description {
    margin-top: 2rem;
  }

  .service__details {
    margin-top: 2rem;
    padding: 0 10vw;
    p {
      margin-left: -20px;
    }

    ul {
      margin-top: 2rem;
      line-height: 1.5rem;

      &.two-columns {
        columns: 2;
      }
    }
  }

  .service__back {
    margin-top: 3rem;
  }
}

.services__btn {
  a {
    text-decoration: none;
    background-color: #323232;
    padding: 0.8rem 2rem;
    color: white;
    border: 0;
  }
}

@media only screen and (max-width: 991px) {
  .services {
    padding: 0 5vw;

    .services__items {
      flex-direction: column;

      .services__item {
        flex-basis: auto;
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .other-services {
    .other-services__items {
      .other-services__item {
        flex-direction: column;
        .other-services__item--title {
          flex-direction: column;

          h3 {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .service {
    padding-bottom: 2rem;

    h1 {
      font-size: 2rem;
    }

    .service__details {
      ul.two-columns {
        columns: 1;
      }
    }
  }
}
