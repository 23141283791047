#slider {
  position: absolute;
  width: 100px;
  height: 100px;
  background: blue;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

// Slide In
.slide-in {
  animation: slide-in ease-out 1s 0.5s forwards;
  -webkit-animation: slide-in ease-out 1s 0.5s forwards;
}

.slide-out {
  animation: slide-out ease-out 1s 0.5s forwards;
  -webkit-animation: slide-out ease-out 1s 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

// Fade In
.fade-in {
  animation: fadeIn-low ease 2s;
  -webkit-animation: fadeIn-low ease 2s;
  -moz-animation: fadeIn-low ease 2s;
  -o-animation: fadeIn-low ease 2s;
  -ms-animation: fadeIn-low ease 2s;

  &.opacity-low {
    animation-name: fadeIn-low;
    -webkit-animation-name: fadeIn-low;
    -moz-animation-name: fadeIn-low;
    -o-animation-name: fadeIn-low;
    -ms-animation-name: fadeIn-low;
  }

  &.opacity-medium {
    animation-name: fadeIn-medium;
    -webkit-animation-name: fadeIn-medium;
    -moz-animation-name: fadeIn-medium;
    -o-animation-name: fadeIn-medium;
    -ms-animation-name: fadeIn-medium;
  }

  &.opacity-high {
    animation-name: fadeIn-high;
    -webkit-animation-name: fadeIn-high;
    -moz-animation-name: fadeIn-high;
    -o-animation-name: fadeIn-high;
    -ms-animation-name: fadeIn-high;
  }
}

$opacities: (
  "low": 0,
  "medium": 0.5,
  "high": 0.8,
);

@each $name, $opacity in $opacities {
  @keyframes fadeIn-#{$name} {
    0% {
      opacity: $opacity;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn-#{$name} {
    0% {
      opacity: $opacity;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn-#{$name} {
    0% {
      opacity: $opacity;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn-#{$name} {
    0% {
      opacity: $opacity;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn-#{$name} {
    0% {
      opacity: $opacity;
    }
    100% {
      opacity: 1;
    }
  }
}
