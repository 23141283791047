$white: #ffffff;
$black: #000000;
$primary_color: rgb(237, 205, 31);
$secondary_color: rgb(13, 29, 102);
$bg_color: rgb(30, 45, 59);

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.white {
  color: $white;
}

.bg-primary {
  background-color: $primary_color;
}

.headul {
  width: 10rem;
  height: 0.8rem;
  background-color: $primary_color;
  margin: 1rem 0;
  display: inline-block;
}

.section-header {
  text-align: center;
}

h1 {
  font-size: 3.5rem;
  text-align: center;
  text-transform: uppercase;
}

.mobile-header {
  display: none;
}

.header,
.footer {
  top: 0;
  z-index: 2;
  height: 13vh;
  max-height: 99px;
  display: flex;
  align-items: center;
  padding: 0 10rem;
  justify-content: space-evenly;
  background-image: url("../assets/webp/header_background.webp"), url("../assets/jpg/header_background.jpg");
  // box-shadow: 0px 3px 5px 0px #00000045;
  position: sticky;

  .header__logo-container {
    height: 100%;
    min-width: 10vw;

    img,
    object {
      height: 100%;
    }
  }

  .header__links {
    display: flex;
    list-style: none;
    text-align: center;
    align-items: center;

    li {
      margin-left: 2rem;

      a {
        color: $secondary_color;
        font-weight: 600;
        font-size: 1.1rem;
        text-decoration: none;
        text-transform: uppercase;

        &.active,
        &:hover {
          color: $primary_color;
        }
      }
    }
  }
}

hr.divider {
  height: 1rem;
  border: 0;
  margin: 4rem 10%;
  background-color: #edcd1f;
}

.footer {
  margin-top: 5rem;
}

.scrollFix {
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

@media only screen and (max-width: 991px) {
  .header,
  .footer {
    display: none;
  }

  .mobile-header {
    display: flex;
  }
}
