// calculate a circle's circumference based on radius
@function circumference($r) {
  $pi: 3.141592653;
  @return 2 * $pi * $r;
}

.mobile-header {
  height: 13vh;
  max-height: 99px;
  background-image: url("../assets/webp/header_background.webp"), url("../assets/jpg/header_background.jpg");
  position: sticky;
  z-index: 2;
  top: 0;
}

// ---------------------------
// Main Navigation Menu
// ---------------------------

.nav {
  width: 100%;

  // Toggle Button
  &__toggle {
    display: inline-block;
    position: absolute;
    z-index: 10;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
    right: 30px;
    top: 15px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.15s linear;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__logo img,
  &__logo object {
    height: 12vh;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 5;
    visibility: hidden;
    padding-top: 10vh;
  }

  &__item {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0, 0.995, 0.99, 1) 0.3s;
  }

  @for $i from 1 through 5 {
    &__item:nth-child(#{$i}) {
      transform: translateY(-40px * $i);
    }
  }

  &__link {
    color: $primary_color;
    display: block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 1.25rem;
    text-decoration: none;
    padding: 1rem;

    &:hover,
    &:focus {
      outline: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

// ---------------------------
// SVG Menu Icon
// ---------------------------

.menuicon {
  display: block;
  cursor: pointer;
  color: $bg_color;

  transform: rotate(0deg);
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &__bar,
  &__circle {
    fill: none;
    stroke: currentColor;
    stroke-width: 3;
    stroke-linecap: round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-in-out;
  }
  &__circle {
    transition: stroke-dashoffset 0.3s linear 0.1s;
    stroke-dashoffset: circumference(23); // 23 is the <circle>'s radius
    stroke-dasharray: circumference(23);
  }
}

// ---------------------------
// Circular Splash Background
// ---------------------------

.splash {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 1px;
  height: 1px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: $bg_color;

    // screen diameter can be 142vmax at most,
    // circle needs to be twice that size to cover it
    width: 284vmax;
    height: 284vmax;
    top: -142vmax;
    left: -142vmax;

    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);

    // will-change tells the browser we plan to
    // animate this property in the near future
    will-change: transform;
  }
}

// ---------------------------
// Active State
// ---------------------------

.nav:target,
.nav--open {
  //scale the background circle to full size
  > .splash::after {
    transform: scale(1);
  }

  //animate the menu icon
  .menuicon {
    color: $primary_color;
    transform: rotate(180deg);

    &__circle {
      stroke-dashoffset: 0;
    }
    &__bar:nth-child(1),
    &__bar:nth-child(4) {
      opacity: 0;
    }
    &__bar:nth-child(2) {
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      transform: rotate(-45deg);
    }
  }

  //show the nav items
  .nav {
    &__menu {
      visibility: visible;
    }

    &__item {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
